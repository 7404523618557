import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FullscreenMessage from './FullscreenMessage';
import ShowSurvey from './ShowSurvey';
import { FormattedMessage, IntlProvider} from 'react-intl';
import en from "./intl/en.json";
import de from "./intl/de.json";
import jp from "./intl/jp.json";

class App extends React.Component {

  constructor(props) {
      super(props);
      this.state.lang = "de";//this.getLang();
   }

   state = {
     window:'',
     info_message:'',
     fullscreen_message:false,
     survey_id:'',
     location_id:'',
   }

   componentDidMount() {
     var uri = window.location.pathname;
     var locationID = uri.substring(uri.lastIndexOf("/")+1);
     if(locationID==null || locationID.length==0) {
       this.showFullScreenMessage("sorry, no locationID defined");
       return;
     }
     uri = uri.substring(0,uri.lastIndexOf("/"));
     console.log("---->now URI="+uri);
     var surveyID = uri.substring(uri.lastIndexOf("/")+1);
     if(surveyID===null || surveyID.length===0 || !this.isNumber(surveyID)) {
       this.showFullScreenMessage("sorry, wrong request");
       return;
     }
     //console.log("xxxx survey_id="+surveyID+" locationID="+locationID);
     this.setState({survey_id:surveyID,location_id:locationID});
   }

   componentDidUpdate() {
   }

   getLang() {
     var lang = this.getNavigatorLanguage();
     if (lang.indexOf("-")>0) {
       return lang.substring(0,2);
     }else{
       return lang;
     }
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   isNumber(n) {
     return !isNaN(parseFloat(n)) && isFinite(n);
   }

   showFullScreenMessage(message) {
     this.setState({info_message:message,fullscreen_message:true});
   }

   render() {
      const messages = {en,de,jp};
      //console.log("--->uri="+uri);
      return (
        <IntlProvider locale={this.state.lang} messages={messages[this.state.lang]}>
        {this.state.fullscreen_message?
            <FullscreenMessage message={this.state.info_message} />
            :
            this.state.survey_id.length>0?<ShowSurvey survey_id={this.state.survey_id} location_id={this.state.location_id} />:<span/>
        }
        </IntlProvider>
      );
   }

}

export default App;
