import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { FormattedMessage} from 'react-intl';

class FullscreenMessage extends React.Component {

  constructor(props) {
      super(props);
   }

   componentDidMount() {
   }

   componentDidUpdate() {
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   render() {
      return (
        <div style={{margin:"5%",height:'90%',width:'90%',justifyContent:"center",display:"flex"}}>
          <Card>
            <CardContent>
                <Typography variant="h4">{this.trans(this.props.message)}</Typography>
            </CardContent>
          </Card>
        </div>
      );
   }

}

export default FullscreenMessage;
