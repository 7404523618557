import './App.css';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FullscreenMessage from './FullscreenMessage';
import { FormattedMessage} from 'react-intl';
import MoodIcon from '@mui/icons-material/Mood';
import TagFacesTwoToneIcon from '@mui/icons-material/TagFacesTwoTone';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import TextField from '@mui/material/TextField';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import Chart from "react-google-charts";

class ShowSurvey extends React.Component {

  constructor(props) {
      super(props);
   }

   state = {
     window:'',
     info_message:'',
     question:'',
     selected_btn:0,
     cust_name:'',
     comment:'',
     ans:[],
     data:[],
   }

   componentDidMount() {
     //console.log("surveyID="+this.props.survey_id);
     this.loadSurvey();
     var sID = localStorage.getItem("etv_survey_"+this.props.survey_id);
     if( sID==="done") {
       //this.showFullScreenMessage("already_participated");
       this.showData();
     }
   }

   componentDidUpdate() {
   }

   getNavigatorLanguage() {
      if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
      } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
      }
   }

   translateDate( d) {
      var date = new Date(d);
			var options = { year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit" };
			return date.toLocaleString(this.getNavigatorLanguage(),options);
   }

   extractSurveyData(answersHits) {
     var data = [];
     data[0] = ["Answer","Hits"];
     var answers = answersHits;
     for(var i=0; i<answers.length; i++) {
       //var hits = Math.floor(Math.random() * 10);
       data[i+1] = [answers[i].answer,answers[i].hits];
       //data[i+1] = [answers[i].answer,hits];
     }
     //console.log("xxxxxx data="+JSON.stringify(data));
     return data;
   }

   loadSurvey() {
     var surveyID = this.props.survey_id;
     var url = "https://elevatortvbackend.appspot.com/survey_mgmt?action=survey_info&survey_id="+surveyID;
     fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
              //console.log(JSON.stringify(result));
              if (result["result"]===false) {
                this.showFullScreenMessage(result["reason"]);
                return;
              }
              var answers = result['ans'];
              //console.log("answers.length="+answers.length);
              var ans = [];
              for(var i=0; i<answers.length; i++) {
                ans[i]=answers[i].answer;
              }
              this.setState({question:result['question'],ans:ans,data:this.extractSurveyData(answers)});
           });
   }

   reportHit() {
     var surveyID = this.props.survey_id;
     var url = "https://elevatortvbackend.appspot.com/survey_mgmt?action=report_hit&survey_id="+surveyID+"&answer_id="+this.state.selected_btn+"&location_id="+this.props.location_id;
     if( this.state.comment.length>0 || this.state.cust_name.length>0 ) {
       url += "&comment="+encodeURIComponent(this.state.comment.length>0?this.state.comment:"")+"&cust_name="+encodeURIComponent(this.state.cust_name.length>0?this.state.cust_name:"");
     }
     fetch(url)
         .then(res => res.json())
         .then(
           (result) => {
              //console.log(JSON.stringify(result));
              if (result["result"]===false) {
                this.showFullScreenMessage(result["reason"]);
                return;
              }
              localStorage.setItem("etv_survey_"+surveyID,"done");
              //this.showFullScreenMessage("thank_you_for_participation");
              var updated = this.state.data;
              updated[this.state.selected_btn][1] = updated[this.state.selected_btn][1] + 1;
              this.setState({data:updated});
              this.showData();
           });
   }

   showFullScreenMessage(message) {
     this.setState({info_message:message,window:'fullscreen_message'});
   }

   showData() {
     this.setState({window:'analyze_data'});
   }

   trans( msgID) {
     return <FormattedMessage id={msgID} defaultMessage={"["+msgID+"]"} />
   }

   showTerms() {
     this.setState({window:'terms_conditions'});
   }

   closeDialog() {
     this.setState({window:''});
   }

   isSatisfactionSurvey() {
     return this.state.ans[0]==='excellent' && this.state.ans[1]==='good' && this.state.ans[2]==='average' && this.state.ans[3]==='poor' && this.state.ans[4]==='very poor';
   }

   render() {
      return (
        <div style={{margin:"2.5%",height:'90%',width:'95%',justifyContent:"center"}}>
        {this.state.window==='fullscreen_message'? <FullscreenMessage message={this.state.info_message} />:
         this.state.window==='analyze_data'?
            <Card>
              <CardContent>
                  <Typography variant="h5">{this.state.question}</Typography>
                  <Chart width={400} height={400} chartType="BarChart" loader={<div>loading</div>} data={this.state.data}
                      options={{
                        title: this.props.chart_title,
                        chartArea: { width: '50%' },
                        hAxis: {
                          title: '',
                          minValue: 0,
                        },
                        vAxis: {
                          title: '',
                          minValue: 0,
                        },
                        legend: { position: "none" },
                      }}
                  />
              </CardContent>
            </Card>
            :
            <Card>
              <CardContent>
                <Typography variant="h5">{this.state.question}</Typography>
                {this.isSatisfactionSurvey()?
                  <div>
                    {this.state.ans.length>0?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:1})}} variant={this.state.selected_btn===1?'contained':'outlined'} color='primary' size="large" fullWidth={true}><MoodIcon style={{fontSize:70,color:'#fcba03'}} /><Typography variant="h5" style={{marginLeft:30}}>{this.trans("excellent")}</Typography></Button>:<span/>}
                    {this.state.ans.length>1?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:2})}} variant={this.state.selected_btn===2?'contained':'outlined'} color='primary' size="large" fullWidth={true}><SentimentSatisfiedAltIcon style={{fontSize:70,color:'#fcba03'}} /><Typography variant="h5" style={{marginLeft:30}}>{this.trans("good")}</Typography></Button>:<span/>}
                    {this.state.ans.length>2?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:3})}} variant={this.state.selected_btn===3?'contained':'outlined'} color='primary' size="large" fullWidth={true}><SentimentSatisfiedIcon style={{fontSize:70,color:'#fcba03'}} /><Typography variant="h5" style={{marginLeft:30}}>{this.trans("average")}</Typography></Button>:<span/>}
                    {this.state.ans.length>3?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:4})}} variant={this.state.selected_btn===4?'contained':'outlined'} color='primary' size="large" fullWidth={true}><SentimentVeryDissatisfiedIcon style={{fontSize:70,color:'#fcba03'}} /><Typography variant="h5" style={{marginLeft:30}}>{this.trans("bad")}</Typography></Button>:<span/>}
                    {this.state.ans.length>4?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:5})}} variant={this.state.selected_btn===5?'contained':'outlined'} color='primary' size="large" fullWidth={true}><MoodBadIcon style={{fontSize:70,color:'#fcba03'}} /><Typography variant="h5" style={{marginLeft:30}}>{this.trans("terrible")}</Typography></Button>:<span/>}
                    <TextField id="i1" onChange={(e)=>{this.setState({cust_name:e.target.value})}} InputProps={{style:{fontSize:25}}}  InputLabelProps={{ style:{fontSize:20} }} style={{marginTop:30}} fullWidth={true} label={this.trans("name_optional")} variant="outlined" />
                    <TextField id="i2" onChange={(e)=>{this.setState({comment:e.target.value})}}   InputProps={{style:{fontSize:25}}}  InputLabelProps={{ style:{fontSize:20} }} multiline rows={4} style={{marginTop:30}} fullWidth={true} label={this.trans("comment_optional")} variant="outlined" multiline={3} />
                  </div>
                :
                  <div>
                    {this.state.ans.length>0?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:1})}} variant={this.state.selected_btn===1?'contained':'outlined'} color='primary' size="large" fullWidth={true}><Typography variant="h5" style={{margin:10, marginLeft:30}}>{this.state.ans[0]}</Typography></Button>:<span/>}
                    {this.state.ans.length>1?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:2})}} variant={this.state.selected_btn===2?'contained':'outlined'} color='primary' size="large" fullWidth={true}><Typography variant="h5" style={{margin:10, marginLeft:30}}>{this.state.ans[1]}</Typography></Button>:<span/>}
                    {this.state.ans.length>2?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:3})}} variant={this.state.selected_btn===3?'contained':'outlined'} color='primary' size="large" fullWidth={true}><Typography variant="h5" style={{margin:10, marginLeft:30}}>{this.state.ans[2]}</Typography></Button>:<span/>}
                    {this.state.ans.length>3?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:4})}} variant={this.state.selected_btn===4?'contained':'outlined'} color='primary' size="large" fullWidth={true}><Typography variant="h5" style={{margin:10, marginLeft:30}}>{this.state.ans[3]}</Typography></Button>:<span/>}
                    {this.state.ans.length>4?<Button style={{justifyContent:"flex-start",marginTop:20}} onClick={()=>{this.setState({selected_btn:5})}} variant={this.state.selected_btn===5?'contained':'outlined'} color='primary' size="large" fullWidth={true}><Typography variant="h5" style={{margin:10, marginLeft:30}}>{this.state.ans[4]}</Typography></Button>:<span/>}
                  </div>
                }
                <Button style={{marginTop:50}} disabled={this.state.selected_btn<1} onClick={()=>{this.reportHit()}} variant='contained' color='success' size="large" fullWidth={true}><Typography variant="h5">{this.trans("submit")}</Typography></Button>
              </CardContent>
              <CardActions style={{justifyContent:'center'}}>
              </CardActions>
            </Card>
        }
        </div>
      );
   }

}

export default ShowSurvey;
